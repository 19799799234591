import React, { useEffect, useState } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Section from '@components/Section'
import Container from '@components/Container'
import Heading from '@components/Heading'
import Text from '@components/Text'
import Box from '@components/Box'
import { theme } from '@lib/theme'
import styled from 'styled-components'

const textStyles = `
color: ${theme.colors.greyscale[6]};
font-weight: 500;
font-size: 24px;
line-height: 34px;
max-width: 750px;
background-color: transparent;
font-family: ${theme.fonts.primary};
@media screen and (max-width: ${theme.maxWidths.small}px) {
  font-size: 18px;
line-height: 30px;
}
`

const StyledContainer = styled(Container)`
  z-index: 2;
`
const StyledText = styled(Text)`
  ${textStyles}
`

const StyledLink = styled.a`
  border: 0px;
  outline: 0px;
  padding: 0px;
  border-bottom: 2px solid ${theme.colors.greyscale[6]};
  cursor: pointer;
  text-decoration: none;
  transition: color 300ms ease, border 300ms ease;
  &:hover {
    color: ${theme.colors.greyscale[8]};
    border-bottom: 2px solid ${theme.colors.greyscale[8]};
  }
  ${textStyles}
`

const HomeJoinSection: React.FC = () => {
  const [fbHasLoaded, setFbHasLoaded] = useState<boolean>(false)

  useEffect(() => {
    const interval = setInterval(() => {
      const fbMessengerWrapper = document.querySelector(
        '.fb_dialog'
      ) as HTMLDivElement

      if (fbMessengerWrapper) {
        setFbHasLoaded(true)
        clearInterval(interval)
      }
    }, 2000)
    return () => clearInterval(interval)
  }, [])

  return (
    <Section
      backgroundColor={theme.colors.greyscale[0]}
      id='join'
      paddingTop={['100px', null, '200px', null]}
    >
      <StyledContainer size='xsmall' position='relative'>
        <Heading
          color={theme.colors.greyscale[8]}
          as='h2'
          fontSize={['42px', '54px', null, null]}
          lineHeight={['50px', '64px', null, null]}
        >
          JOIN THE CLUB
        </Heading>
        <StyledText mt={['30px', '60px', null, null]} mb='30px'>
          The easiest way to get in touch is to{' '}
          <span>
            <StyledLink
              href={
                fbHasLoaded
                  ? 'javascript:void(0)'
                  : 'https://www.facebook.com/Thechopshopbarbellclub'
              }
              target={fbHasLoaded ? '' : '_blank'}
              onClick={() => {
                if (fbHasLoaded) {
                  ;(window as any).FB.CustomerChat.showDialog()
                }
              }}
            >
              send us a Facebook messsage
            </StyledLink>
          </span>{' '}
          or to call / text{' '}
          <span>
            <StyledLink href='tel:0211354166'>021 135 4166</StyledLink>
          </span>
          .
        </StyledText>
        <StyledText>
          If you want to see us in person, turn up after 6pm on a weekday at{' '}
          <span>
            <StyledLink
              target='_blank'
              href='https://goo.gl/maps/ZDuackMdtaun6oVd6'
            >
              Unit 7c, 667 Whangaparaoa Road, Stanmore Bay, 0932 Auckland
            </StyledLink>
          </span>
          .
        </StyledText>
      </StyledContainer>
      <Box
        position='relative'
        height={['600px', '700px', '900px', null]}
        marginTop={['-200px', '-350px', null, null]}
      >
        <StaticImage
          src='../../images/chop-shop-map.png'
          alt=''
          objectFit='cover'
          style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
          quality={100}
          loading='eager'
          placeholder='none'
        />
        <Box
          position='absolute'
          top='0'
          right='0'
          bottom='0'
          left='0'
          width='100%'
          height='100%'
          background='linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 20%, rgba(0,0,0,0) 50%)'
        />
      </Box>
    </Section>
  )
}

export default HomeJoinSection
