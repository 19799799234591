import React from 'react'
import Link, { LinkProps } from '@components/Link'
import Text from '@components/Text'
import { theme } from '@lib/theme'

export interface NavLinkProps extends LinkProps {
  to: string
  primary?: boolean
}

const NavLink: React.FC<NavLinkProps> = ({ to, primary, children }) => {
  return (
    <Link
      to={to}
      color={primary ? 'white' : theme.colors.greyscale[8]}
      style={{
        textDecoration: 'none',
        backgroundColor: primary ? '#880808' : 'transparent',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '3px',
        display: 'inline-flex',
      }}
    >
      <Text
        fontWeight='700'
        fontSize={['14px', '16px', '18px', null]}
        lineHeight='48px'
        height='48px'
        minHeight='48px'
        padding={
          primary
            ? ['0px 30px', null, null, null]
            : ['0px 10px', '0px 30px', null, null]
        }
      >
        {children}
      </Text>
    </Link>
  )
}

export default NavLink
