import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import Section from '@components/Section'
import Container from '@components/Container'
import Heading from '@components/Heading'
import Text from '@components/Text'
import Box from '@components/Box'
import { theme } from '@lib/theme'
import { faqs } from './data'

const StyledHeading = styled(Heading)`
  color: ${theme.colors.greyscale[7]};
  font-weight: 700;
  transition: color 300ms ease;
`

const StyledText = styled(Text)`
  font-weight: 500;
  line-height: 28px;
  color: ${theme.colors.greyscale[6]};
  padding-left: 30px;
`

const AccordionBtn = styled.button`
  all: unset;
  display: block;
  color: ${theme.colors.greyscale[7]};
  box-sizing: border-box;
  width: 100%;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  align-items: center;
  &::before {
    all: unset;
  }
`

const AccordionContentWrapper = styled(Box)<{
  active: boolean
}>`
  ${({ active }) => css`
    opacity: ${active ? '1' : '0'};
    overflow: hidden;
    height: ${active ? 'auto' : '0px'};
    transition: all 500ms ease;
    border-radius: 4px;
    margin-top: ${active ? '8px' : '0px'};
    position: relative;
    color: white;
  `}
`

const HomeFaqSection: React.FC = () => {
  const [activeFaq, setActiveFaq] = useState<number>(0)
  return (
    <Section
      backgroundColor={theme.colors.greyscale[0]}
      paddingTop={['100px', null, '200px', null]}
      id='faqs'
    >
      <Container size='xsmall'>
        <Heading
          as='h2'
          fontSize={['42px', '54px', null, null]}
          lineHeight={['50px', '64px', null, null]}
          mb='60px'
          color={theme.colors.greyscale[8]}
        >
          FAQS
        </Heading>
        {faqs.map((faq, i) => (
          <Box
            key={`accordion_item_${i}`}
            paddingBottom='15px'
            marginBottom='15px'
            borderBottom={
              i === faqs.length - 1
                ? null
                : `1px solid ${theme.colors.greyscale[6]}`
            }
          >
            <Box position='relative'>
              <AccordionBtn onClick={() => setActiveFaq(i)}>
                <Box
                  backgroundColor='#880808'
                  height='12px'
                  width='12px'
                  minWidth='12px'
                  borderRadius='100%'
                  mr='18px'
                />
                <StyledHeading
                  as='h3'
                  textAlign='left'
                  fontSize={['20px', '24px', null, null]}
                  lineHeight={['28px', '32px', null, null]}
                >
                  {faq.question}
                </StyledHeading>
              </AccordionBtn>
            </Box>
            <AccordionContentWrapper active={activeFaq === i}>
              <StyledText>{faq.answer}</StyledText>
            </AccordionContentWrapper>
          </Box>
        ))}
      </Container>
    </Section>
  )
}

export default HomeFaqSection
