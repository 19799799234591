export const faqs = [
  {
    question: 'Are you open to new members?',
    answer: `Yes. As long as you're willing to listen to yelling, swearing, loud music and grunting. You'll also need the desire to train to get stronger — we are focused on serious lifting, not mirror selfies. If that sounds like your scene then come try the Chop Shop.`,
  },
  {
    question: `I'm not that strong, can I still join?`,
    answer: `It's how strong you want to be that matters to us. As long as you want to train to get stronger you are welcome to join.`,
  },
  {
    question: `What does "Chop Shop" mean?`,
    answer: 'We do wood chopping as well as powerlifting. 🪓',
  },
  {
    question: `How much does it cost to be a member?`,
    answer: `$80 per month with a minimum term of 3 months.`,
  },
  {
    question: `Do you offer coaching?`,
    answer: `Not specifically, however there are experienced lifters at the gym who will be happy to help you.`,
  },
  {
    question: `Can you make me a training program?`,
    answer: `We help any members with programming who need it.`,
  },
  {
    question: `What are the gym's opening hours?`,
    answer: `The gym is open every week night from 6pm onwards as well as Saturday mornings from 11am to about 2.30pm.`,
  },
]
