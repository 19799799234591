import React from 'react'
import styled, { css } from 'styled-components'
import { ALL, AllProps, theme } from '@lib/theme'

export interface ContainerProps extends AllProps {
  size?: 'xsmall' | 'small' | 'regular'
  noPaddingOnMobile?: boolean
}

const StyledContainer = styled.div<ContainerProps>`
  ${({ size, noPaddingOnMobile }) => css`
    padding: 0px 24px;
    width: 100%;
    max-width: ${size === 'xsmall'
      ? '800px'
      : size === 'small'
      ? '1000px'
      : '1200px'};
    margin: 0 auto;
    ${ALL}
    @media screen and (max-width: ${theme.maxWidths.medium}px) {
      padding: ${noPaddingOnMobile ? '0px' : '0px 24px'};
    }
  `}
`

const Container: React.FC<ContainerProps> = ({
  size = 'regular',
  children,
  noPaddingOnMobile,
  ...props
}) => (
  <StyledContainer size={size} noPaddingOnMobile={noPaddingOnMobile} {...props}>
    {children}
  </StyledContainer>
)

export default Container
