import styled from 'styled-components'
import { ALL, AllProps } from '@lib/theme'

export interface ListProps extends AllProps {}

const List = styled.ul<ListProps>`
  ${ALL}
`

export default List
