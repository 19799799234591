// Extend the styled-component DefaultTheme with our extended SDS theme
declare module 'styled-components' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface DefaultTheme extends Theme {}
}

export interface Theme {
  colors: {
    white: string
    primary: string[]
    secondary: string[]
    greyscale: string[]
    positive: string[]
    negative: string[]
    warning: string[]
    red: string[]
    gradient: string[]
    blackfade15: string
    blackfade20: string
    blackfade30: string
    blackfade35: string
    blackfade50: string
    whitefade15: string
    whitefade20: string
    whitefade35: string
    whitefade50: string
  }
  breakpoints: string[]
  maxWidths: {
    xsmall: number
    small: number
    medium: number
    large: number
    xlarge: number
    xxlarge: number
  }
  space: number[]
  sizes: number[]
  fonts: {
    primary: string
  }
  fontWeights: {
    light: number
    regular: number
    medium: number
    bold: number
  }
  radii: number[]
  shadows: {
    small: string
    medium: string
    input: string
    inputFocus: string
  }
  zIndices: {
    modal: number
    overlay: number
    dropdown: number
    layerThree: number
    layerTwo: number
    layerOne: number
    foreground: number
    background: number
  }
}

export const theme: Theme = {
  colors: {
    // Brand
    white: '#fff',
    primary: [
      '#266DD3',
      '#04122B',
      '#051F4D',
      '#12358E',
      '#8C9DC9',
      '#C0D0F9',
      '#DAE2F9',
      '#DEE7FF',
      '#EFF3FF',
      '#FBFCFF',
    ],
    secondary: [
      '#FF813E',
      '#310F04',
      '#601D09',
      '#DA6E4D',
      '#FFA58A',
      '#FEC3B1',
      '#FFD1C3',
      '#FFE4DC',
      '#FFF2EE',
      '#FFF2EE',
    ],
    gradient: ['linear-gradient(90deg, #FF374E 0%, #FF813E 100%)'],
    greyscale: [
      '#000000',
      '#0C0C0C',
      '#1C1C1C',
      '#242424',
      '#575757',
      '#949494',
      '#BBBBBB',
      '#C4C4C4',
      '#ECECEC',
      '#E2E2E2',
      '#FFFFFF',
      '#FAFAFA',
      '#F4F4F4',
    ],

    // Semantic
    positive: [
      '#00A878',
      '#17451C',
      '#45A54E',
      '#63D66E',
      '#89D991',
      '#9ADFA1',
      '#99E3A1',
      '#ABE4B1',
      '#BCE9C0',
      '#DDF4E0',
    ],
    negative: [
      '#D65454',
      '#671B1B',
      '#AA3D3D',
      '#E46060',
      '#DE7676',
      '#E28787',
      '#E69898',
      '#EAA9A9',
      '#EEBABA',
      '#F7DDDD',
    ],
    warning: [
      '#FEB92B',
      '#4E3605',
      '#AA7915',
      '#FCC24A',
      '#FBCE69',
      '#FDE29E',
      '#FDEABD',
      '#FFF5D2',
      '#FFF8E1',
      '#FFFAEA',
    ],
    red: ['#880808'],
    // Fades
    blackfade15: 'rgba(27, 31, 35, 0.15)',
    blackfade20: 'rgba(27, 31, 35, 0.20)',
    blackfade30: 'rgba(27, 31, 35, 0.3)',
    blackfade35: 'rgba(27, 31, 35, 0.35)',
    blackfade50: 'rgba(27, 31, 35, 0.5)',
    whitefade15: 'rgba(255, 255, 255, 0.15)',
    whitefade20: 'rgba(255, 255, 255, 0.20)',
    whitefade35: 'rgba(255, 255, 255, 0.35)',
    whitefade50: 'rgba(255, 255, 255, 0.5)',
  },
  breakpoints: ['544px', '768px', '1012px', '1280px', '1440px', '1920px'],
  maxWidths: {
    xsmall: 544,
    small: 768,
    medium: 1012,
    large: 1280,
    xlarge: 1440,
    xxlarge: 1920,
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  sizes: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fonts: {
    primary: 'Bitter, serif',
  },
  fontWeights: {
    light: 200,
    regular: 300,
    medium: 400,
    bold: 500,
  },
  radii: [0, 2, 4],
  shadows: {
    small: '0px 10px 24px rgba(0, 0, 0, 0.03)',
    medium: '0px 10px 24px rgba(0, 0, 0, 0.06)',
    input: '0px 2px 3px rgba(0, 0, 0, 0.07)',
    inputFocus: '0px 1px 5px rgba(10, 67, 210, 0.15)',
  },
  zIndices: {
    modal: 6000,
    overlay: 4000,
    dropdown: 3000,
    layerThree: 2500,
    layerTwo: 2000,
    layerOne: 1500,
    foreground: 1000,
    background: -1000,
  },
}
