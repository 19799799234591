import { ALL, AllProps } from '@lib/theme'
import styled from 'styled-components'

export interface HeadingProps extends AllProps {}

const Heading = styled.h1<HeadingProps>`
  ${ALL}
`

export default Heading
