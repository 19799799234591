import { ALL, AllProps } from '@lib/theme'
import styled from 'styled-components'

export interface TextProps extends AllProps {}

const Text = styled.p<TextProps>`
  ${ALL}
`

export default Text
