import {
  space,
  SpaceProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  typography,
  TypographyProps,
  flexbox,
  FlexboxProps,
  shadow,
  ShadowProps,
  border,
  BorderProps,
  display,
  DisplayProps,
  background,
  BackgroundProps,
  color as ssColor,
  ColorProps as SSColorProps,
  TextColorProps,
  compose,
  system,
} from 'styled-system'

export interface ColorProps extends Omit<SSColorProps, 'color'> {
  textColor?: TextColorProps['color']
}

export type AllProps = SpaceProps &
  LayoutProps &
  PositionProps &
  TypographyProps &
  FlexboxProps &
  ShadowProps &
  BorderProps &
  DisplayProps &
  BackgroundProps &
  ColorProps

export const color = compose(
  ssColor,
  system({
    // Alias color as textColor
    textColor: {
      property: 'color',
      // This connects the property to your theme, so you can use the syntax shown
      // below E.g "primary.0".
      scale: 'colors',
    },
  })
)

// All Styled System props (except Grid ;).
export const ALL = compose(
  space,
  color,
  layout,
  display,
  position,
  typography,
  flexbox,
  shadow,
  border,
  background,
  shadow
)

export type InnerStyledComponent<T, H> = T &
  React.HTMLAttributes<H> & {
    as?: keyof JSX.IntrinsicElements
  }
