import React, { ReactNode } from 'react'
import { ThemeProvider, createGlobalStyle } from 'styled-components'
import { theme } from './theme'
import { globalReset } from './globalReset'
import { globalTypography } from './globalTypography'

interface GlobalThemeProviderProps {
  children: ReactNode
}

const GlobalStyle = createGlobalStyle`
${globalReset};
${globalTypography};
body, html {
  background-color: black;
}
`

export const GlobalThemeProvider = ({ children }: GlobalThemeProviderProps) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      {children}
    </ThemeProvider>
  )
}
