import styled from 'styled-components'
import { ALL, AllProps } from '@lib/theme'

export interface ListItemProps extends AllProps {}

const ListItem = styled.li<ListItemProps>`
  ${ALL}
`

export default ListItem
