import React from 'react'
import Layout from '@components/Layout'
import HomeHeroSection from '@sections/HomeHeroSection'
import HomeAboutSection from '@sections/HomeAboutSection'
import HomeFaqSection from '@sections/HomeFaqSection'
import HomeJoinSection from '@sections/HomeJoinSection'
import HtmlHead from '@components/HtmlHead'

const Home: React.FC = () => {
  return (
    <>
      <HtmlHead
        title='Chop Shop Barbell'
        metaDescription='Chop Shop Barbell is a powerlifting club in Whangaparaoa, Auckland. We offer a specialised gym that caters to competitive powerlifters, serious strength athletes, and anyone who wants to get stronger.'
      />
      <Layout>
        <HomeHeroSection />
        <HomeAboutSection />
        <HomeFaqSection />
        <HomeJoinSection />
      </Layout>
    </>
  )
}

export default Home
