import { ALL, AllProps } from '@lib/theme'
import { Link as GatsbyLink } from 'gatsby'
import styled from 'styled-components'

export interface LinkProps extends AllProps {}

const Link = styled(GatsbyLink)<LinkProps>`
  ${ALL}
`

export default Link
