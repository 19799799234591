import React from 'react'
import { Helmet } from 'react-helmet'
import bitterReg from '@fonts/Bitter/BitterRegular.ttf'
import bitterMed from '@fonts/Bitter/BitterMedium.ttf'
import bitterSemiB from '@fonts/Bitter/BitterSemiBold.ttf'
import bitterBold from '@fonts/Bitter/BitterBold.ttf'
import defaultOgImage from '../../images/og.png'

type HelmetProps = {
  title: string
  canonicalLink?: string
  metaDescription: string
  openGraphImage?: string
}

const HtmlHead: React.FC<HelmetProps> = ({
  title,
  canonicalLink,
  metaDescription,
  openGraphImage,
}) => {
  return (
    <Helmet>
      <meta charSet='utf-8' />
      <html lang='en' />
      <title>{title}</title>
      <link
        rel='preload'
        as='font'
        href={bitterReg}
        type='font/ttf'
        crossOrigin='anonymous'
      />
      <link
        rel='preload'
        as='font'
        href={bitterMed}
        type='font/ttf'
        crossOrigin='anonymous'
      />
      <link
        rel='preload'
        as='font'
        href={bitterSemiB}
        type='font/ttf'
        crossOrigin='anonymous'
      />
      <link
        rel='preload'
        as='font'
        href={bitterBold}
        type='font/ttf'
        crossOrigin='anonymous'
      />
      {canonicalLink && <link rel='canonical' href={canonicalLink} />}
      <meta name='description' content={metaDescription} />
      <meta property='og:type' content='website' />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={metaDescription} />
      <meta property='og:image' content={openGraphImage || defaultOgImage} />
      <meta property='twitter:card' content='summary_large_image' />
      <meta property='twitter:title' content={title} />
      <meta property='twitter:description' content={metaDescription} />
      <meta
        property='twitter:image'
        content={openGraphImage || defaultOgImage}
      />
      <script type='application/javascript'>{`
             window.fbAsyncInit = function () {
                FB.init({
                  xfbml: true,
                  version: 'v10.0',
                })
              }
            
              ;(function (d, s, id) {
                var js,
                  fjs = d.getElementsByTagName(s)[0]
                if (d.getElementById(id)) return
                js = d.createElement(s)
                js.id = id
                js.src = 'https://connect.facebook.net/en_GB/sdk/xfbml.customerchat.js'
                fjs.parentNode.insertBefore(js, fjs)
              })(document, 'script', 'facebook-jssdk')
            `}</script>
      {/* Global site tag (gtag.js) - Google Analytics  */}
      <script
        async
        src='https://www.googletagmanager.com/gtag/js?id=G-M8XB6BXZNM'
      ></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-M8XB6BXZNM');
        `}
      </script>
    </Helmet>
  )
}

export default HtmlHead
