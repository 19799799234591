import { css } from 'styled-components'
import { between } from 'polished'
import { breakpointsRange } from './breakpointsRange'

export const globalTypography = css`
  ${({ theme }) => css`
    body {
      font-family: ${theme.fonts.primary};
      font-weight: 300;
      color: ${theme.colors.greyscale[0]};
      font-size: ${between('15px', '16px', ...breakpointsRange(theme))};
      line-height: ${between('22px', '26px', ...breakpointsRange(theme))};
    }

    h1 {
      font-size: ${between('50px', '50px', ...breakpointsRange(theme))};
      font-weight: 700;
      font-family: ${theme.fonts.primary};
    }

    h2 {
      font-size: ${between('24px', '24px', ...breakpointsRange(theme))};
      font-weight: 600;
      font-family: ${theme.fonts.primary};
    }

    h3 {
      font-size: ${between('24px', '24px', ...breakpointsRange(theme))};
      font-weight: 300;
      font-family: ${theme.fonts.primary};
    }

    h4 {
      font-size: ${between('17px', '20px', ...breakpointsRange(theme))};
      font-weight: 300;
      font-family: ${theme.fonts.primary};
    }

    h5 {
      font-size: ${between('16px', '16px', ...breakpointsRange(theme))};
      font-weight: 300;
      font-family: ${theme.fonts.primary};
    }

    p {
      font-weight: 500;
      font-size: ${between('18px', '18px', ...breakpointsRange(theme))};
      font-weight: 300;
      font-family: ${theme.fonts.primary};
    }
  `}
`
