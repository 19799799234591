import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Box from '@components/Box'
import styled from 'styled-components'
import './styles.css'
import { theme } from '@lib/theme'

const imgStyles: React.CSSProperties = {
  width: '100%',
  height: '100%',
  position: 'absolute',
  objectFit: 'cover',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
}

const portraitImgStyles: React.CSSProperties = {
  width: '50%',
  margin: '0 auto',
  objectFit: 'contain',
  objectPosition: '50% 50%',
}

const StyledBox = styled(Box)`
  height: 648px;
  position: relative;
  overflow: hidden;
  @media screen and (max-width: 1199px) {
    height: calc((100vw - 48px) * (9 / 16));
  }
  @media screen and (max-width: ${theme.maxWidths.medium}px) {
    height: calc(100vw * (9 / 16));
  }
`

const ImageCarousel: React.FC = () => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <Slider className='image-slider' {...settings}>
      <StyledBox>
        <StaticImage
          src='../../images/gym-img-1-b.jpg'
          alt=''
          style={imgStyles}
          loading='eager'
          placeholder='none'
          quality={100}
        />
      </StyledBox>
      <StyledBox>
        <StaticImage
          src='../../images/gym-img-2-b.jpg'
          alt=''
          style={imgStyles}
          loading='eager'
          placeholder='none'
          quality={100}
        />
      </StyledBox>
      <StyledBox>
        <StaticImage
          src='../../images/gym-img-3-b.jpg'
          alt=''
          style={imgStyles}
          aspectRatio={3 / 4}
          imgStyle={portraitImgStyles}
          placeholder='none'
          loading='eager'
          quality={100}
        />
      </StyledBox>
      <StyledBox>
        <StaticImage
          src='../../images/gym-img-4-b.jpg'
          alt=''
          style={imgStyles}
          aspectRatio={3 / 4}
          imgStyle={portraitImgStyles}
          loading='eager'
          placeholder='none'
          quality={100}
        />
      </StyledBox>
      <StyledBox>
        <StaticImage
          src='../../images/gym-img-5-b.jpg'
          alt=''
          style={imgStyles}
          aspectRatio={3 / 4}
          imgStyle={portraitImgStyles}
          loading='eager'
          placeholder='none'
          quality={100}
        />
      </StyledBox>
    </Slider>
  )
}

export default ImageCarousel
