import React, { useEffect, useState } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import Container from '@components/Container'
import Link from '@components/Link'
import List from '@components/List'
import Box from '@components/Box'
import Banner from '@components/Banner'
import ListItem from '@components/ListItem'
import NavLink from '@components/Navbar/NavLink'
import { ALL, AllProps, theme } from '@lib/theme'

export interface NavbarProps extends AllProps {}

const StyledNav = styled.div`
  transition: background-color 300ms ease, border 300ms ease;
  height: 80px;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 100;
  ${ALL}
`

const StyledLi = styled(ListItem)`
  display: inline-block;
  height: 100%;
  margin-top: 16px;
`

const Navbar: React.FC<NavbarProps> = ({ ...props }) => {
  const [bgIsDark, setBgisDark] = useState<boolean>(false)

  const setBg = () => {
    const scrollTop = window.scrollY
    scrollTop > 0 ? setBgisDark(true) : setBgisDark(false)
  }

  useEffect(() => {
    setBg()
    window.addEventListener('scroll', () => setBg())
  }, [])

  return (
    <StyledNav
      backgroundColor={bgIsDark ? theme.colors.greyscale[0] : 'transparent'}
      borderBottom={
        bgIsDark
          ? `1px solid ${theme.colors.greyscale[2]}`
          : '1px solid transparent'
      }
      {...props}
    >
      <Container display='flex' justifyContent='space-between' height='100%'>
        <Box display={['none', 'block', null, null]}>
          <Link
            to='/'
            style={{
              height: '48px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '16px',
            }}
          >
            <StaticImage
              src='../../images/chop-shop-text-white-smoke.png'
              alt='Chop Shop Barbell Club'
              placeholder='none'
              height={36}
              loading='eager'
            />
          </Link>
        </Box>

        <List
          position='relative'
          display='flex'
          width='100%'
          justifyContent={['flex-start', 'flex-end', null, null]}
        >
          <StyledLi>
            <NavLink to='/#about'>ABOUT</NavLink>
          </StyledLi>
          <StyledLi>
            <NavLink to='/#faqs'>FAQS</NavLink>
          </StyledLi>
          <StyledLi
            width={['100%', 'auto', null, null]}
            display='inline-flex'
            position='relative'
          >
            <Box
              position={['absolute', 'relative', null, null]}
              right={['0px', 'auto', null, null]}
            >
              <NavLink to='/#join' primary>
                JOIN THE CLUB
              </NavLink>
            </Box>
          </StyledLi>
        </List>
      </Container>
    </StyledNav>
  )
}

export default Navbar
