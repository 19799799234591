import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Section from '@components/Section'
import Container from '@components/Container'
import Box from '@components/Box'
import { theme } from '@lib/theme'

const HomeHeroSection: React.FC = () => {
  return (
    <Section
      backgroundColor={theme.colors.greyscale[0]}
      height='100vh'
      position='relative'
    >
      <Box
        position='absolute'
        top='0'
        right='0'
        bottom='0'
        left='0'
        width='100%'
        height='100%'
      >
        <StaticImage
          src='../../images/straightened-crop-b.jpg'
          alt=''
          objectFit='cover'
          objectPosition='center bottom'
          style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
          loading='eager'
          placeholder='none'
          quality={100}
        />
        <Box
          position='absolute'
          top='0'
          right='0'
          bottom='0'
          left='0'
          width='100%'
          height='100%'
          backgroundColor='rgba(0, 0, 0, 0.4)'
          boxShadow='inset 0 15vh 15vh 0 rgba(0,0,0,0.8)'
        />
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        height='100%'
        paddingTop='calc(25vh + 40px)'
        paddingBottom='25vh'
      >
        <Container
          height='100%'
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          position='relative'
        >
          <StaticImage
            src='../../images/chop-shop-white.png'
            alt='Chop Shop Barbell Club'
            placeholder='none'
            loading='eager'
            objectFit='contain'
          />
        </Container>
      </Box>
    </Section>
  )
}

export default HomeHeroSection
