import React from 'react'
import Section from '@components/Section'
import Container from '@components/Container'
import Heading from '@components/Heading'
import Text from '@components/Text'
import ImageCarousel from '@components/ImageCarousel'
import { theme } from '@lib/theme'

const HomeAboutSection: React.FC = () => {
  return (
    <Section
      backgroundColor={theme.colors.greyscale[0]}
      paddingTop={['100px', null, '200px', null]}
      id='about'
    >
      <Container size='xsmall'>
        <Heading
          color={theme.colors.greyscale[8]}
          as='h2'
          fontSize={['42px', '54px', null, null]}
          lineHeight={['50px', '64px', null, null]}
        >
          THE CLUB
        </Heading>
        <Text
          color={theme.colors.greyscale[6]}
          fontWeight='500'
          fontSize='24px'
          lineHeight='32px'
          mt='40px'
          mb='60px'
          maxWidth='750px'
        >
          The Chop Shop is a powerlifting club in Whangaparaoa, Auckland. We
          offer a specialised gym that caters to competitive powerlifters,
          serious strength athletes, and anyone who wants to get stronger.
        </Text>
      </Container>
      <Container noPaddingOnMobile>
        <ImageCarousel />
      </Container>
    </Section>
  )
}

export default HomeAboutSection
