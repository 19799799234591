import React from 'react'
import { GlobalThemeProvider } from '@lib/theme'
import Navbar from '@components/Navbar'
import Footer from '@components/Footer'
import Box from '@components/Box'
import '@lib/theme/fonts.css'

const Layout: React.FC = ({ children }) => {
  return (
    <GlobalThemeProvider>
      <Box position='relative'>
        <Navbar />
        {children}
        <Footer />
      </Box>
      <div
        className='fb-customerchat'
        // @ts-ignore
        attribution='page_inbox'
        page_id='1691896864416924'
      />
      <div id='fb-root' />
    </GlobalThemeProvider>
  )
}

export default Layout
