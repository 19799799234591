import React from 'react'
import styled from 'styled-components'
import Container from '@components/Container'
// import List from '@components/List'
// import ListItem from '@components/ListItem'
import Box from '@components/Box'
import { StaticImage } from 'gatsby-plugin-image'
import { ALL, AllProps, theme } from '@lib/theme'
import Text from '@components/Text'
import Link from '@components/Link'

export interface FooterProps extends AllProps {}

const StyledFooter = styled.footer<FooterProps>`
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 25px 0px 5px 0px;
  ${ALL}
`

const Footer: React.FC = () => {
  return (
    <StyledFooter backgroundColor='transparent'>
      <Container>
        <Box
          margin='0 auto'
          width='100%'
          maxWidth={['175px', '350px', null, null]}
          display='flex'
          justifyContent='center'
          mb='20px'
        >
          <StaticImage
            src='../../images/chop-shop-text-white-smoke.png'
            alt='Chop Shop Barbell Club'
            placeholder='none'
            quality={100}
            loading='eager'
          />
        </Box>
        <Box
          margin='0 auto'
          width='100%'
          display='flex'
          justifyContent='center'
          alignItems='center'
        >
          <Link
            as='a'
            href='https://www.facebook.com/Thechopshopbarbellclub'
            target='_blank'
            minHeight='48px'
            minWidth='48px'
            display='flex'
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
          >
            <StaticImage
              src='../../images/facebook.png'
              alt=''
              placeholder='none'
              quality={100}
              loading='eager'
              height={22}
            />
          </Link>
          <Link
            as='a'
            href='https://www.instagram.com/chop_shop_barbell/?hl=en'
            target='_blank'
            minHeight='48px'
            minWidth='48px'
            display='flex'
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
          >
            <StaticImage
              src='../../images/instagram.png'
              alt=''
              placeholder='none'
              quality={100}
              loading='eager'
              height={22}
            />
          </Link>
          <Text
            marginLeft='12px'
            color={theme.colors.greyscale[7]}
            fontSize={['11px', '12px', null, null]}
            lineHeight='16px'
            textAlign={['left', null, 'center', null]}
          >
            © The Chop Shop Barbell Club {new Date().getFullYear()} | It's just
            boring fine print, stop being nosey.
          </Text>
        </Box>
      </Container>
    </StyledFooter>
  )
}

export default Footer
